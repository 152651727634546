<template>
  <div class="breadcrumbs-app">
    <v-breadcrumbs
      v-bind="breadcrumbsVOptions"
      :items="getBreadcrumbsItems"
    ></v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/breadcrumbs/
     */
    breadcrumbsVOptions: {
      type: Object,
      default() {
        return {
          light: true
        }
      }
    }
  },
  computed: {
    /**
     * Get breadcrumbs path (items)
     */
    getBreadcrumbsItems() {
      const currentRoute = this.$route
      return [
        {
          text: 'Inicio',
          disabled: false,
          href: '/'
        },
        {
          text: currentRoute.name,
          disabled: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.breadcrumbs-app {
  .v-breadcrumbs {
    padding: 6px 0;
  }
}
</style>
