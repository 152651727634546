<template>
  <PanelSection :title="formLabels.labelSectionTitle" :hide-back-button="false">
    <div class="panel-users-view">
      <v-container>
        <CustomCard :card-v-options="{ width: '50%' }">
          <v-form novalidate @submit.prevent="onSubmit">
            <!-- name -->
            <v-text-field
              v-model="$v.formFields.name.$model"
              label="Nombre *"
              :error-messages="checkFieldErrors('name')"
              @input="touchField('name')"
              @blur="touchField('name')"
            />
            <!-- Rol -->
            <v-select
              v-model="$v.formFields.role.$model"
              :items="getUserRoles"
              item-value="id"
              item-text="name"
              label="Rol de usuario"
              :error-messages="checkFieldErrors('role')"
              @input="touchField('role')"
              @blur="touchField('role')"
            />
            <!-- email -->
            <v-text-field
              v-model="$v.formFields.email.$model"
              label="Email *"
              :disabled="Boolean(id)"
              :error-messages="checkFieldErrors('email')"
              @input="touchField('email')"
              @blur="touchField('email')"
            />
            <!-- phone -->
            <v-text-field
              v-model="$v.formFields.phone.$model"
              label="Teléfono"
              :error-messages="checkFieldErrors('phone')"
              @input="touchField('phone')"
              @blur="touchField('phone')"
            />
            <!-- password -->
            <template v-if="!id">
              <v-text-field
                v-model="$v.formFields.password.$model"
                data-cy="password-input"
                label="Contraseña"
                :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="togglePassword ? 'text' : 'password'"
                :error-messages="checkFieldErrors('password')"
                @input="touchField('password')"
                @blur="touchField('password')"
                @click:append="togglePassword = !togglePassword"
              />
            </template>
            <!-- Activo -->
            <v-switch
              v-model="$v.formFields.active.$model"
              label="Usuario activo"
            ></v-switch>
            <!-- action buttons -->
            <v-card-actions>
              <FormButtons
                :accept-button-text="formLabels.labelButtonSave"
                :accept-button-loading="formProcessing"
                cancel-button-hide
              />
            </v-card-actions>
          </v-form>
        </CustomCard>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import { ROLES } from '@/constants'
// Components
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
import PanelSection from '@/components/ui/PanelSection'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
// Services
import { createUserWithEmailAndPassword } from '@/services/authentication'
import {
  createPanelUser,
  updatePanelUser,
  getPanelUserById
} from '@/services/panelUser'

export default {
  name: 'PanelUsersForm',
  components: { CustomCard, FormButtons, PanelSection },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Data item
      id: this.$route.params.id || null,
      // Form
      formFields: {
        active: true,
        email: null,
        name: null,
        password: null,
        phone: null,
        role: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        name: {
          required: 'Campo obligatorio'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        },
        role: {
          required: 'Campo obligatorio'
        }
      },
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    /**
     * Get the labels (texts) to show in the formulary
     */
    formLabels() {
      return {
        labelButtonSave: this.id ? 'Guardar' : 'Crear',
        labelSectionTitle: this.id ? 'Editar usuario' : 'Crear usuario'
      }
    },
    /**
     * Get the differents roles app
     */
    getUserRoles() {
      return Object.entries(ROLES).map(item => {
        return {
          id: item[0],
          name: item[1].label || item[0]
        }
      })
    }
  },
  async mounted() {
    await this.setItemData(this.id)
  },
  methods: {
    /**
     * Set the item data
     */
    async setItemData(id) {
      if (id) {
        const itemData = await getPanelUserById(id)
        this.formFields = { ...this.formFields, ...itemData }
      }
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        if (!this.id) {
          // Create a new user
          const userRegistered = await createUserWithEmailAndPassword(
            this.formFields.email,
            this.formFields.password,
            {
              displayName: this.formFields.name
            }
          )
          await createPanelUser(this.formFields, userRegistered.user.uid)
        } else {
          // Update user
          await updatePanelUser({ ...this.formFields, id: this.id })
        }
        // show info
        this.modifyAppAlert({
          text: 'Los cambios se guardaron correctamente',
          visible: true
        })
        // Redirect to list
        this.routerPushTo({
          path: '/panel-users'
        })
      } catch (error) {
        // show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations() {
    const rules = {
      formFields: {
        active: {},
        email: {
          required,
          email
        },
        name: {
          required
        },
        phone: {},
        role: {
          required
        }
      }
    }

    if (!this.id) {
      rules.formFields.password = {
        required,
        minLength: minLength(6)
      }
    }

    return rules
  }
}
</script>
