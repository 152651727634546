<template>
  <div class="panel-section">
    <!-- ToolBar -->
    <ToolBar :title="title" :hide-tool-bar-back-button="hideToolBarBackButton">
      <!-- BreadCrumbs -->
      <template v-slot:toolbar-extension-left>
        <BreadCrumbs v-if="false" />
      </template>
      <!-- Actions -->
      <template v-slot:toolbar-extension-right>
        <template v-if="!hideActionButtons">
          <v-btn
            small
            color="primary"
            :elevation="0"
            @click="handleClickAddButton"
            >{{ primaryButtonLabel }}</v-btn
          >
        </template>
      </template>
    </ToolBar>
    <!-- Content -->
    <div class="pl-md-4 pr-md-4 mx-lg-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// Components
import ToolBar from '@/components/ui/ToolBar'
import BreadCrumbs from '@/components/ui/BreadCrumbs'

export default {
  name: 'PanelSection',
  components: { ToolBar, BreadCrumbs },
  props: {
    hideActionButtons: {
      type: Boolean,
      default: true
    },
    hideBreadcrumbs: {
      type: Boolean,
      default: false
    },
    hideToolBarBackButton: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Title'
    },
    primaryButtonLabel: {
      type: String,
      default: 'Añadir'
    }
  },
  methods: {
    /**
     * Handle on click "Add button"
     *
     * @param {object} $event - event action
     */
    handleClickAddButton($event) {
      this.$emit('onClickAddButton', $event)
    }
  }
}
</script>
