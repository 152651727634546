<template>
  <div class="toolbar-app" data-cy="toolbar-app">
    <!-- Toolbar -->
    <v-toolbar v-bind="toolBarVOptions">
      <v-btn v-if="!hideToolBarBackButton" icon @click="handleClickBackButton">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <template v-slot:extension>
        <div class="extension">
          <div class="extension-left">
            <slot name="toolbar-extension-left"></slot>
          </div>
          <div class="extension-right">
            <slot name="toolbar-extension-right"></slot>
          </div>
        </div>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'ToolBar',
  mixins: [uiMixin],
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    hideToolBarBackButton: {
      default: false,
      type: Boolean
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/toolbars/#toolbars
     */
    toolBarVOptions: {
      default() {
        return {
          dense: true,
          extended: true,
          flat: true
        }
      },
      type: Object
    }
  },
  methods: {
    /**
     * Handle Back Button
     */
    handleClickBackButton() {
      this.routerGoTo(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.toolbar-app {
  border-bottom: 1px solid $theme-border-color;
  .v-toolbar__content {
    & > .v-btn.v-btn--icon:first-child {
      & + .v-toolbar__title {
        padding-left: 0;
        font-size: 1.15rem;
        line-height: 1.35rem;
      }
    }
    .v-toolbar__title {
      font-size: 1.15rem;
      line-height: 1.35rem;
    }
  }
  .v-toolbar__extension {
    .extension {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center;
      align-items: space-between;
      .extension-left,
      .extension-right {
        flex: 1;
      }
      .extension-left {
        text-align: left;
      }
      .extension-right {
        text-align: right;
      }
    }
  }
}
</style>
